import { Injectable } from "@angular/core";
import { UserPermissions } from "../models/tenancies";
import { Feature } from "../models/feature-toggles";
import { LoggerService } from "./logger.service";

@Injectable({
  providedIn: "root",
})
export class FeatureTogglesService {
  constructor(private log: LoggerService) {}

  private getCurrentTenant(user: UserPermissions): string {
    return user?.user?.current_tenant ?? localStorage.getItem("tenant") ?? "vodafone";
  }

  private isVodafone(user: UserPermissions): boolean {
    return this.getCurrentTenant(user) === "vodafone";
  }

  private isPcs(user: UserPermissions): boolean {
    return this.isVodafone(user) && (user?.user?.tenancies ?? []).includes("pcs");
  }

  public getEnabledFeatures(user: UserPermissions): Feature[] {
    return (user?.user?.groups?.["Anomaly-Detection"] ?? []).filter((v: string): boolean => {
      return v !== "tenancy_viewers" && v !== "tenancy_owners";
    }) as Feature[];
  }

  public isFeatureEnabled(user: UserPermissions, feature: Feature): boolean {
    const isPcs: boolean = this.isPcs(user);
    const enabledFeatures: Feature[] = this.getEnabledFeatures(user);
    const optedIn: boolean = enabledFeatures.includes(feature);
    const isEnabled: boolean = isPcs || optedIn;
    this.log.info({ user, feature, isPcs, isEnabled, enabledFeatures, optedIn });
    return isEnabled;
  }
}
