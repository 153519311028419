import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { LoggerService } from "./logger.service";
import { NavigationEnd, Router } from "@angular/router";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { ArrowPosition } from "../models/tutorial";
import { CLASS_NAME, DURATION_PER_CHAR, SNACKBAR_CLASS, TUTORIALS } from "../constant/tutorial";
import { AuthorisationService } from "./authorisation.service";
import { SignInEvent } from "../models/tenancies";
import { BehaviorSubject, Observable, of } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import Swal from "sweetalert2";
import { TenantService } from "./tenant.service";

// two weeks ago
const OLD_DATE = new Date((new Date().getTime() / 1000 - 3600 * 24 * 14) * 1000);

@Injectable({
  providedIn: "root",
})
export class TutorialService {
  public tutorialInProgress = false;
  private timeouts: number[] = [];
  public hasTutorials = false;
  public currentTutorial = "";
  private signInEvents: Array<SignInEvent>;

  public stopTutorial = new BehaviorSubject<boolean>(false);

  constructor(
    private snackBar: MatSnackBar,
    private tenant: TenantService,
    private log: LoggerService,
    private router: Router,
    private authZ: AuthorisationService,
    private authN: AuthenticationService
  ) {}

  private getSignInEvents(): Observable<Array<SignInEvent>> {
    if (this.signInEvents) {
      return of(this.signInEvents);
    } else {
      return this.authZ.getUserPermissions({ attributes: ["sign_in_events"] }).pipe(
        map((userDetails) => {
          return userDetails?.user?.sign_in_events ?? [];
        }),
        tap((events) => {
          this.signInEvents = events;
          this.log.info({ signInIevents: events });
        })
      );
    }
  }

  private async waitForOverlayLoader(maxTries = 20, sleepDuration = 100): Promise<void> {
    await this.sleep(sleepDuration);
    for (let i = 0; i < maxTries; i++) {
      const overloadLoader = document.getElementById("overlay-loader");
      if (overloadLoader?.classList && overloadLoader?.classList.contains("d-none")) {
        break;
      } else {
        await this.sleep(sleepDuration);
      }
    }
  }

  private async waitTillVisible(
    selector: string,
    textFilter?: string,
    maxTries = 20,
    sleepDuration = 100
  ): Promise<void> {
    for (let i = 0; i < maxTries; i++) {
      const elements: HTMLElement[] = this.findElements(selector, textFilter);
      if (elements.length > 0) {
        break;
      } else {
        await this.sleep(sleepDuration);
      }
    }
  }

  public *getRelatedTutorials(tutorials: string[], yielded: Set<string> | null = null) {
    if (yielded === null) {
      yield* this.getRelatedTutorials(tutorials, new Set<string>());
    } else {
      for (const tName of tutorials) {
        yield tName;
        const tutorial = TUTORIALS.find((t) => t?.id === tName);
        const relatedTutorials = (tutorial?.alternativeTutorials ?? []).filter((t) => !yielded.has(t));
        if (relatedTutorials.length > 0) {
          yielded.add(tName);
          yield* this.getRelatedTutorials(relatedTutorials, yielded);
        }
      }
    }
  }

  public async replayTutorial(): Promise<void> {
    const tutorialNames: string[] = TUTORIALS.filter((t) => t.url(location?.href ?? ""))
      .map((t) => t?.id)
      .filter(Boolean);

    for (const tName of this.getRelatedTutorials(tutorialNames)) {
      localStorage.removeItem(`tutorial-${tName}`);
    }

    for (const tName of tutorialNames) {
      await this.beginTutorial(tName);
    }
  }

  private openSnackBar(message: string, panelClass: string, duration?: number): void {
    const config = new MatSnackBarConfig();
    config.duration = duration ?? 10000;
    config.panelClass = [panelClass];
    config.politeness = "assertive";
    config.announcementMessage = "Tutorial";
    config.horizontalPosition = "center";
    config.verticalPosition = "bottom";
    this.snackBar.open(message, null, config);
  }

  private sleep(milliseconds: number) {
    return new Promise((resolve) => {
      const timeout = setTimeout(resolve, milliseconds) as unknown as number;
      this.timeouts.push(timeout);
    });
  }

  public hasSeenTutorial(name: string): boolean {
    return !!localStorage.getItem(`tutorial-${name}`);
  }

  public hasSeenAlternativeTutorial(tutorialName: string): boolean {
    for (const tName of this.getRelatedTutorials([tutorialName])) {
      if (this.hasSeenTutorial(tName)) {
        return true;
      }
    }
    return false;
  }

  public setup(): void {
    if (this.authN.isLoggedIn()) {
      this.isNewUser().subscribe(async (isNew) => {
        this.log.info({ isNew, msg: "checked if is new user based on sign in events" });
        const href = location?.href ?? "";
        for (const tutorial of TUTORIALS) {
          try {
            if (tutorial.url(href)) {
              this.hasTutorials = true;
              this.currentTutorial = tutorial?.id ?? "";
              if (isNew || this.authN.email === "norbert.logiewa@vodafone.com") {
                await this.beginTutorial(this.currentTutorial);
              }
            }
          } catch (e) {
            this.log.error(e);
          }
        }
      });
    }

    this.router.events
      .pipe(
        filter((val) => val instanceof NavigationEnd),
        tap(() => {
          this.hasTutorials = false;
        }),
        filter(() => this.authN.isLoggedIn()),
        switchMap(() => this.isNewUser())
      )
      .subscribe(async (isNew) => {
        this.log.info({ isNew, msg: "checked if is new user based on sign in events" });
        const href = location?.href ?? "";
        for (const tutorial of TUTORIALS) {
          try {
            if (tutorial.url(href)) {
              this.hasTutorials = true;
              this.currentTutorial = tutorial?.id ?? "";
              if (isNew) {
                await this.beginTutorial(this.currentTutorial);
              }
            }
          } catch (e) {
            this.log.error(e);
          }
        }
      });
  }

  private focusOn(element: HTMLElement): void {
    try {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    } catch (e) {
      this.log.error(e);
    }
  }

  private addClass(elements: HTMLElement[]): void {
    for (const e of elements) {
      try {
        e.classList.add(CLASS_NAME);
      } catch (e) {
        this.log.error(e);
      }
    }
    if (elements.length > 0) {
      document.body.classList.add("darkening");
    }
  }

  private findElements(selector: string, textFilter?: string): HTMLElement[] {
    const elements: HTMLElement[] = [];
    if (textFilter) {
      document.querySelectorAll(selector).forEach((e) => {
        // @ts-ignore
        if ((e?.innerText ?? "").includes(textFilter)) {
          // @ts-ignore
          if (e.offsetParent) {
            elements.push(e as HTMLElement);
          }
        }
      });
    } else {
      document.querySelectorAll(selector).forEach((e) => {
        // @ts-ignore
        if (e.offsetParent) {
          elements.push(e as HTMLElement);
        }
      });
    }
    return elements;
  }

  private isNewUser(): Observable<boolean> {
    return this.getSignInEvents().pipe(
      map((events) => {
        if (events.length <= 2) {
          return true;
        } else {
          return !events
            .filter(Boolean)
            .map((d) => new Date(parseFloat(d.epoch_datetime)))
            .filter(Boolean)
            .some((e) => e <= OLD_DATE);
        }
      })
    );
  }

  private getElementOffsetFromTop(element: HTMLElement): number {
    return element.getBoundingClientRect().top;
  }

  private getElementOffsetFromLeft(element: HTMLElement): number {
    return element.getBoundingClientRect().left;
  }

  private enableScroll(wheelEvent, preventDefault, wheelOpt): void {
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  }

  private preventScroll() {
    // modern Chrome requires { passive: false } when adding event
    let supportsPassive = false;

    function preventDefault(e) {
      e.preventDefault();
    }

    try {
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          get: function () {
            supportsPassive = true;
          },
        })
      );
    } catch (e) {}

    const wheelOpt = supportsPassive ? { passive: false } : false;
    const wheelEvent = "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    return { wheelEvent, preventDefault, wheelOpt };
  }

  private async beginTutorial(name: string): Promise<void> {
    if (this.tutorialInProgress || this.hasSeenTutorial(name) || this.hasSeenAlternativeTutorial(name)) {
      this.log.info(`Already seen tutorial ${name} or alternative`);
      return;
    }

    const userResponse = await Swal.fire({
      title: "Tutorial",
      text: `Would you like to play "${this.currentTutorial}" tutorial on how to use this page?`,
      confirmButtonText: "Play",
      cancelButtonText: "Cancel",
      showCancelButton: true,
      showCloseButton: false,
      allowOutsideClick: false,
      confirmButtonColor: this.tenant.colors.primary,
      cancelButtonColor: this.tenant.colors.secondary,
    });

    if (!userResponse?.isConfirmed) {
      localStorage.setItem(`tutorial-${this.currentTutorial}`, new Date().toISOString());
      this.log.info(`User declined to play tutorial ${name}`);
      return;
    }

    this.tutorialInProgress = true;

    const tutorial = TUTORIALS.find((t) => t.id === name);

    await this.waitForOverlayLoader();

    this.log.info(`beginning tutorial: ${name}`);

    const scroll = this.preventScroll();

    // wait for the first element(s) to become visible
    let fst = true;
    const steps = tutorial?.steps ?? [];
    for (const { selector, text, textFilter, position } of steps) {
      if (this.stopTutorial.value) {
        this.timeouts.forEach(clearTimeout);
        this.timeouts = [];
        this.clearClass();
        this.hideArrow();
        this.enableScroll(scroll.wheelEvent, scroll.preventDefault, scroll.wheelOpt);
        this.tutorialInProgress = false;
        this.stopTutorial.next(false);
        localStorage.setItem(`tutorial-${name}`, new Date().toISOString());
        this.log.info(`ended tutorial: ${name}`);
        return;
      }
      if (fst && selector) {
        await this.waitTillVisible(selector, textFilter);
        fst = false;
      }
      let elements: HTMLElement[] = [];
      if (selector) {
        elements = this.findElements(selector, textFilter);
        if ((elements?.length ?? 0) === 0) {
          this.log.warning(`not able to find element for selector ${selector}`);
        } else {
          this.addClass(elements);
          this.hideArrow();
          this.focusOn(elements[0]);
        }
      }
      const durationOfStep = DURATION_PER_CHAR * ((text ?? "")?.length ?? 0);

      if (!selector || elements.length > 0) {
        this.openSnackBar(text, SNACKBAR_CLASS, durationOfStep);
        if (elements.length > 0) {
          if (durationOfStep >= 3000) {
            this.sleep(1000).then(() => {
              this.showArrow(
                this.getElementOffsetFromLeft(elements[0]),
                this.getElementOffsetFromTop(elements[0]),
                elements[0],
                position
              );
            });
          }
        }
        await this.sleep(durationOfStep + 500);
      }

      this.timeouts.forEach(clearTimeout);
      this.timeouts = [];
      this.clearClass();
    }

    this.hideArrow();
    this.enableScroll(scroll.wheelEvent, scroll.preventDefault, scroll.wheelOpt);
    localStorage.setItem(`tutorial-${name}`, new Date().toISOString());
    this.tutorialInProgress = false;
    this.log.info(`ended tutorial: ${name}`);
  }

  private showArrow(x: number, y: number, element: HTMLElement, position: ArrowPosition = "left"): void {
    const arrow: HTMLElement = document.getElementById("tutorial-arrow");
    // Position the arrow

    let offsetX = 0;
    let offsetY = 0;

    if (position === "left") {
      arrow.style.transform = "rotate(115deg)";
      offsetX = -120;
      offsetY = -65;
    } else if (position === "right") {
      arrow.style.transform = "rotate(-115deg)";
      offsetX = 120;
      offsetY = -65;
    } else if (position === "bottom") {
      offsetY = 65;
      offsetX = Math.floor(element.getBoundingClientRect().width / 8);
      // default position
      arrow.style.transform = "rotate(0deg)";
    } else if (position === "top") {
      offsetY = -65;
      offsetX = Math.floor(element.getBoundingClientRect().width / 8);
      arrow.style.transform = "rotate(180deg)";
    }

    arrow.style.left = `${Math.floor(x + offsetX)}px`;
    arrow.style.top = `${Math.floor(y + offsetY)}px`;
    arrow.style.zIndex = "9999";
    arrow.classList.remove("d-none");
    arrow.classList.add("d-block");
  }

  private hideArrow(): void {
    const arrow = document.getElementById("tutorial-arrow") as HTMLElement;
    if (arrow) {
      arrow.classList.remove("d-block");
      arrow.classList.add("d-none");
    }
  }

  private clearClass(): void {
    (document.querySelectorAll(`.${CLASS_NAME}`) || []).forEach((e: HTMLElement) => {
      try {
        e.classList.remove(CLASS_NAME);
      } catch (e) {
        this.log.error(e);
      }
    });
    document.body.classList.remove("darkening");
  }
}
