<div id="outlet-wrapper" class="position-relative d-flex flex-column min-vh-100">
  <div class="min-vh-100" style="padding-bottom: 50px">
    <ng-container *ngIf="isLoggedIn">
      <app-header class="header"></app-header>

      <img
        *ngIf="hasTutorials"
        id="tutorial-arrow"
        class="d-none position-fixed"
        src="/assets/images/tutorial-arrow.svg"
        alt="tutorial-arrow"
      />

      <div *ngIf="router.url.startsWith('/dashboard') && (router.url === '/dashboard' || router.url.includes('#'))">
        <img class="h-100 w-100" src="/assets/images/banner.png" alt="banner" />
      </div>

      <app-breadcrumbs></app-breadcrumbs>
      <div
        *ngIf="popUpGCPActivated"
        [class.d-none]="!showGCPAnnouncements"
        class="position-fixed overflow-auto bg-light p-5 rounded shadow announcements"
      >
        <div>
          <div class="d-flex flex-row justify-content-between align-items-start">
            <h2 class="h2 text-center">Announcements</h2>
            <button tooltip="Close" class="btn clickable m-1" (click)="showGCPAnnouncements = false">
              <app-vficon size="20px" iconPack="source-system-icons" iconName="close"></app-vficon>
            </button>
          </div>
          <div class="my-2 p-3 custom-card">
            <h5>
              We've recently updated the billing formulas for VPC Billing account<span class="text-primary">
                specific period APR-24 to NOV-24</span
              >
              due to the introduction of a new credit for Hybrid Data Fusion service.
            </h5>
          </div>

          <div class="mb-2 custom-card message-card p-3">
            <p class="font-weight-bold">Summary of Changes:</p>
            <ul>
              <li>
                <p>
                  <span class="font-weight-bold">Promotions Removed: </span>All promotions for this billing account have
                  been eliminated.
                </p>
              </li>
              <li>
                <p>
                  <span class="font-weight-bold">HDF Charges: </span>HDF charges have been eliminated at the billing
                  account level.
                </p>
              </li>
              <li class="no-list-style">
                <p class="font-weight-bold">The changes will affect your current projects in the following ways:</p>
              </li>
              <li>
                <p>
                  <span class="font-weight-bold">Cost Display Differences: </span>You might see different cost
                  distributions between the GCP Console and VCloudSmart due to how the credit is applied.<span
                    class="text-primary"
                    >(If you want to match the VCS and GCP values, please remove the promotion from GCP console)</span
                  >
                </p>
              </li>
              <li>
                <p>
                  <span class="font-weight-bold">No More Promotions: </span>Any promotions you had are now removed, so
                  you won’t see those discounts anymore.
                </p>
              </li>
              <li>
                <p>
                  <span class="font-weight-bold">Cost Adjustments: </span>Costs of previous promotions are now added to
                  your GCP costs, which could increase the amount shown.
                </p>
              </li>
              <li>
                <p>
                  <span class="font-weight-bold">HDF Charges Removed: </span>Charges for Hybrid Data Fusion (HDF) at the
                  VPC billing account level are eliminated, which might reduce your overall costs.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        [class.d-none]="!showAI"
        *ngIf="popUpAIActivated && !isLLMPage"
        class="position-fixed overflow-auto bg-light p-5 rounded shadow"
        style="max-height: 80vh; max-width: 480px; bottom: 88px; z-index: 20; right: 80px"
      >
        <app-llm (closeLLM)="showAI = false" [sidebar]="true"></app-llm>
      </div>

      <ul
        *ngIf="hasTutorials || showContactButton || showFeedbackButton"
        class="feedback-buttons d-none d-md-flex list-unstyled flex-nowrap"
        [ngClass]="{ right: isRight }"
      >
        <button
          tooltip="Give feedback about the current page"
          *ngIf="showFeedbackButton"
          class="btn mt-3 give-feedback-btn"
          (click)="toggleFeedbackPopUp()"
        >
          <app-vficon
            style="position: relative; bottom: 3px"
            iconPack="source-system-icons"
            iconName="like"
          ></app-vficon>
        </button>
        <a tooltip="Reach out to CCE team" *ngIf="showContactButton" class="btn mt-3" [href]="mailToURL">
          <app-vficon
            style="position: relative; bottom: 3px"
            iconPack="source-system-icons"
            iconName="sms"
          ></app-vficon>
        </a>
        <button
          tooltip="GCP New Announcements"
          class="btn mt-3 popup-ai-btn"
          [class.d-none]="!isGCPPage"
          (click)="showGCPAnnouncements = !showGCPAnnouncements"
        >
          <div class="notification-wrapper">
            <app-vficon
              style="position: relative; bottom: 3px"
              iconPack="source-system-icons"
              iconName="notification"
            ></app-vficon>
            <span *ngIf="notificationCount > 0" class="notification-badge">
              {{ notificationCount }}
            </span>
          </div>
        </button>
        <button
          *ngIf="hasTutorials && !tutorialInProgress"
          tooltip="Play '{{ currentTutorial }}' tutorial"
          class="btn mt-3 reset-tutorials-btn"
          (click)="replayTutorial()"
        >
          <app-vficon
            style="position: relative; bottom: 3px"
            iconPack="source-system-icons"
            iconName="play-arrow"
          ></app-vficon>
        </button>

        <button
          *ngIf="hasTutorials && tutorialInProgress"
          tooltip="Stop '{{ currentTutorial }}' tutorial"
          class="btn mt-3 reset-tutorials-btn"
          (click)="tutorial.stopTutorial.next(true)"
        >
          <app-vficon
            style="position: relative; bottom: 3px"
            iconPack="source-system-icons"
            iconName="stop-circle"
          ></app-vficon>
        </button>

        <button
          tooltip="AI Assistant"
          class="btn mt-3 popup-ai-btn"
          [class.d-none]="isLLMPage"
          (click)="showAI = !showAI"
        >
          <app-vficon
            style="position: relative; bottom: 3px"
            iconPack="source-tobi-icons"
            iconName="default"
          ></app-vficon>
        </button>

        <button
          [class.invisible]="window.scrollY < 500"
          tooltip="Navigate to the top of the page"
          class="btn mt-3"
          (click)="scrollToTop()"
        >
          <app-vficon
            style="position: relative; bottom: 3px"
            iconPack="source-system-icons"
            iconName="arrow-up"
          ></app-vficon>
        </button>
      </ul>
    </ng-container>

    <div class="container-fluid d-inline-block p-0 {{ isLoggedIn ? 'min-vh-100' : '' }}">
      <router-outlet></router-outlet>
      <app-user-feedback></app-user-feedback>
    </div>
    <app-footer class="footer-container {{ isLoggedIn ? 'mb-n5' : '' }}"></app-footer>
  </div>
</div>

<div class="overlay-loader" [class.d-none]="!showLoad">
  <div class="logo">
    <img [priority]="true" ngSrc="/assets/images/spinner_logo.svg" alt="overlay-spinner" height="460" width="460" />
  </div>
</div>
