export const GCP_PROJECT_DUPLICATE_LABELS = {
  budget_owner: "BudgetOwner",
  technical_owner: "TechnicalOwner",
  project_owner: "ProjectOwner",
  data_governance_compliance_manager: "DataGovernanceComplianceManager",
};
export const GCP_PROJECT_ATTRS = [
  "ProjectId",
  "ProjectNumber",
  "CreationDate",
  "FolderName",
  "BudgetOwner",
  "DataGovernanceComplianceManager",
  "TechnicalOwner",
  "ParentId",
  "ProjectName",
  "ProjectOwner",
  "UpdateDate",
  "Organisation",
];
export const GCP_PROJECT_LABELS = [
  "bu",
  "budget_owner",
  "business_service",
  "business_service2",
  "business_service3",
  "business_service4",
  "business_service5",
  "confidentiality",
  "cost_centre",
  "custom",
  "data_governance_compliance_manager",
  "enable_sandbox_cleaner",
  "environment",
  "local_market",
  "managed_by",
  "owner_type",
  "pcs_status",
  "po_number_gcp",
  "programme",
  "project_owner",
  "sec_assessment",
  "service_class",
  "service_level",
  "sox",
  "technical_owner",
  "vams_id",
  "wbs",
];
export const GCP_PROJ_REGEX = new RegExp("^/cloud-providers/gcp/projects/[^/]+$");
export const GCP_ENV_TYPES: { [envType: string]: string[] } = {
  NON_PROD: ["sandbox", "dev", "alpha", "alpha-beta", "beta", "nonlive", "test", "tst", "lab"],
  PROD: ["mgt", "pre-prod", "pprd", "prod", "prd", "zeta", "live", "datahub"],
};
