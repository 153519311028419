<div *ngFor="let alert of alerts.alerts">
  <alert (onClosed)="alerts.removeAlert(alert)" [dismissible]="alert.dismissible" [type]="alert.type">
    <span [innerHtml]="'<strong>' + alert.type.toUpperCase() + '</strong> ' + alert.text"></span>
  </alert>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <nav class="navbar navbar-expand-md navbar-white bg-white">
        <a class="navbar-brand d-md-none p-2 m-0" routerLink="/">
          <svg
            style="width: 48px; display: inline-block"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 311.81 311.81"
          >
            <g data-name="<Group>">
              <circle data-name="<Path>" fill="#e60000" cx="155.91" cy="155.91" r="155.91"></circle>
              <path
                data-name="<Path>"
                fill="#fff"
                d="M157.13 242.31c-42.57.14-86.87-36.19-87.06-94.54C69.95 109.18 90.76 72 117.37 50c26-21.49 61.51-35.28 93.76-35.39 4.15 0 8.49.33 11.15 1.23-28.2 5.85-50.64 32.09-50.54 61.86a16.16 16.16 0 00.19 2.52c47.18 11.49 68.6 40 68.73 79.35s-30.97 82.56-83.53 82.74z"
              ></path>
            </g>
          </svg>
        </a>
        <button (click)="isExpanded = !isExpanded" class="navbar-toggler" type="button">
          <span class="navbar-toggler-icon text-dark" style="position: relative; top: 4px; left: 1px">
            <fa-icon style="position: relative; top: 3px" [icon]="faBars"></fa-icon>
          </span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav list-unstyled">
            <li class="nav-item d-none d-md-block mx-2">
              <a class="navbar-brand p-2 m-0" routerLink="/">
                <svg
                  style="width: 48px"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 311.81 311.81"
                >
                  <g data-name="<Group>">
                    <circle data-name="<Path>" fill="#e60000" cx="155.91" cy="155.91" r="155.91"></circle>
                    <path
                      data-name="<Path>"
                      fill="#fff"
                      d="M157.13 242.31c-42.57.14-86.87-36.19-87.06-94.54C69.95 109.18 90.76 72 117.37 50c26-21.49 61.51-35.28 93.76-35.39 4.15 0 8.49.33 11.15 1.23-28.2 5.85-50.64 32.09-50.54 61.86a16.16 16.16 0 00.19 2.52c47.18 11.49 68.6 40 68.73 79.35s-30.97 82.56-83.53 82.74z"
                    ></path>
                  </g>
                </svg>
              </a>
            </li>
            <ng-container *ngFor="let navItem of navBarItems">
              <li
                [ngClass]="{ 'd-none': !isExpanded, 'd-md-block': !isExpanded }"
                [class.disabled]="navItem.isDisabled"
                *ngIf="navItem.isInvisible !== true"
                class="nav-item active"
              >
                <a
                  [href]="navItem.href"
                  target="_blank"
                  class="nav-link mx-2 header-links"
                  container="body"
                  [class.disabled]="navItem.isDisabled"
                  [tooltip]="navItem.tooltip"
                  placement="left"
                >
                  {{ navItem.innerHTML }}
                </a>
                <span></span>
              </li>
            </ng-container>
          </ul>
          <div class="dropdown-list">
            <div id="tenant-dropdown" class="d-none d-xl-block nav-item m-auto" *ngIf="allowedTenants.length > 1">
              <select
                tooltip="Switch between tenants"
                placement="left"
                [ngModel]="currentTenant"
                (change)="switchTenant($event.target.value)"
              >
                <option *ngFor="let t of allowedTenants" [value]="t">{{ t }}</option>
              </select>
            </div>

            <div
              id="admin-dropdown"
              class="nav-item dropdown"
              *ngIf="currentTenant === 'vodafone' && (user.permissions.isPcs || user.permissions.isBrm)"
            >
              <button
                class="text-dark nav-link dropdown-toggle pr-1 btn"
                placement="left"
                container="body"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                tooltip="You are either from PCS or BRM"
              >
                <app-vficon
                  style="position: relative; bottom: 3px"
                  size="23px"
                  iconPack="source-mid-render-light-icons"
                  class="mx-1"
                  iconName="settings"
                ></app-vficon>
              </button>
              <div class="dropdown-menu">
                <ng-container *ngIf="user.permissions.isPcs">
                  <a class="dropdown-item" routerLink="/api-users" [class.active]="pathname === '/api-users'">
                    API Users
                  </a>
                  <div class="dropdown-divider"></div>
                </ng-container>
                <a
                  class="dropdown-item"
                  routerLink="/service-metrics"
                  *ngIf="user.permissions.canSeeServiceMetrics"
                  [class.active]="pathname === '/service-metrics'"
                  placement="left"
                  tooltip="See metrics related to PCS services e.g. how many people signed in into the PCS Portal in the last 3 months"
                >
                  Service Metrics
                </a>
                <a
                  class="dropdown-item"
                  routerLink="/service-usage"
                  *ngIf="user.permissions.canSeeServiceFacts"
                  [class.active]="pathname === '/service-usage'"
                  [queryParams]="{ csp: 'aws' }"
                  placement="left"
                  tooltip="See information on usage of PCS services e.g. how many people are subscribed to auto-remediation"
                >
                  Service Facts
                </a>
                <ng-container *ngIf="user.permissions.canSeeApiUsage">
                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item"
                    [queryParams]="{
                      Api: 'aws-v2',
                      StartTime: startTime,
                      EndTime: endTime
                    }"
                    [class.active]="pathname === '/api-usage'"
                    placement="left"
                    tooltip="View stats on consumption of APIs over time"
                    routerLink="/api-usage"
                  >
                    API Usage
                  </a>
                  <a
                    class="dropdown-item"
                    placement="left"
                    [queryParams]="{ StartTime: startTime, EndTime: endTime }"
                    [class.active]="pathname === '/global-api-usage'"
                    routerLink="/global-api-usage"
                    tooltip="See aggregated API usage between services in a heat map"
                  >
                    Global API Usage
                  </a>
                </ng-container>
                <ng-container *ngIf="user.permissions.isPcs">
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" target="_blank" href="/pcms-utilities/index.html"> PCS OPS Portal </a>
                </ng-container>
                <ng-container *ngIf="!environment.production">
                  <div class="dropdown-divider"></div>
                  <span (click)="$event.stopPropagation()" class="dropdown-item font-weight-bold">Feature Toggles</span>
                  <button
                    (click)="$event.stopPropagation(); environment.featureToggles[ft] = !environment.featureToggles[ft]"
                    *ngFor="let ft of Object.keys(environment.featureToggles)"
                    class="dropdown-item"
                  >
                    <span class="w-100 d-flex justify-content-between flex-row">
                      <span class="mr-3">{{ ft }}</span>
                      <input type="checkbox" [(ngModel)]="environment.featureToggles[ft]" />
                    </span>
                  </button>
                </ng-container>
              </div>
            </div>

            <div class="nav-item dropleft">
              <button
                class="text-dark nav-link dropdown-toggle btn"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <app-vficon
                  style="position: relative; bottom: 3px"
                  size="23px"
                  iconPack="source-mid-render-light-icons"
                  class="mx-1"
                  iconName="signed-in"
                ></app-vficon>
              </button>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item disabled">
                  {{ email }}
                </a>
                <ng-container *ngIf="lastSignIn">
                  <a class="dropdown-item disabled">
                    Last Sign In:
                    {{ lastSignIn.epoch_datetime | date : "long" }}
                  </a>
                  <div class="dropdown-divider"></div>
                </ng-container>
                <ng-container *ngIf="!environment.production">
                  <a class="dropdown-item disabled"
                    >Total Cost:
                    {{
                      events.currency.value.format.format(
                        events.currency.value.exchangeRate
                          .mul(vcs.getTotalQueryCost("all", undefined))
                          .toDecimalPlaces(2)
                          .toNumber()
                      )
                    }}
                  </a>
                  <a class="dropdown-item disabled"
                    >Total LLM Cost:
                    {{
                      events.currency.value.format.format(
                        events.currency.value.exchangeRate
                          .mul(vcs.getTotalQueryCost("llm", undefined))
                          .toDecimalPlaces(2)
                          .toNumber()
                      )
                    }}
                  </a>
                  <a class="dropdown-item disabled"
                    >Total BQ, API GW & Cloud Run Cost:
                    {{
                      events.currency.value.format.format(
                        events.currency.value.exchangeRate
                          .mul(vcs.getTotalQueryCost("bq", undefined))
                          .toDecimalPlaces(2)
                          .toNumber()
                      )
                    }}</a
                  >
                  <a class="dropdown-item disabled">Total #Queries: {{ vcs.getTotalQueryCount() }}</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item disabled"
                    >This Report BigQuery Cost:
                    {{
                      events.currency.value.format.format(
                        events.currency.value.exchangeRate
                          .mul(vcs.getTotalQueryCost("bq", location.pathname))
                          .toDecimalPlaces(2)
                          .toNumber()
                      )
                    }}</a
                  >
                  <a class="dropdown-item disabled">#Queries on this Report: {{ vcs.getThisReportQueries() }}</a>
                  <div class="dropdown-divider"></div>
                </ng-container>
                <a class="dropdown-item disabled">Color Scheme</a>
                <button
                  *ngFor="let s of tenant.availableColorSchemes"
                  (click)="
                    tenant.customiseColorScheme(s); localStorage.setItem('colorScheme', s); window.location.reload()
                  "
                  class="dropdown-item"
                  [class.disabled]="tenant.colorScheme === s"
                >
                  <span style="text-decoration: {{ tenant.colorScheme === s ? 'underline' : 'none' }}">{{ s }}</span>
                </button>
                <div class="dropdown-divider"></div>
                <a target="_blank" class="dropdown-item" href="https://confluence.sp.vodafone.com/x/ixNyEg"> Help </a>
                <a
                  *ngIf="user.permissions.canCreateCredentials || user.permissions.canReadAnyCredentials"
                  [class.active]="pathname === '/api-credentials'"
                  tooltip="View, delete and generate OAuth2 clients for access to APIs"
                  class="dropdown-item"
                  routerLink="/api-credentials"
                >
                  API Credentials
                </a>
                <a
                  [class.active]="pathname === '/user'"
                  tooltip="See the tenancies you are in along with your permissions"
                  class="dropdown-item"
                  routerLink="/user"
                >
                  User Info
                </a>
                <div class="dropdown-divider"></div>
                <button class="btn dropdown-item" (click)="logout()">Log out</button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
