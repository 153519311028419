import { Injectable } from "@angular/core";

import { Alert } from "../models/alerts";

@Injectable({
  providedIn: "root",
})
export class AlertsService {
  public alerts: Array<Alert> = [];

  public removeAlert(alert: Alert): void {
    this.alerts = this.alerts.filter((a) => !(a.text === alert.text && a.type === alert.type));
  }

  private alreadyExists(alert: Alert): boolean {
    return this.alerts.some((a) => a?.text === alert?.text && a?.type === alert?.type);
  }

  public addAlert(alert: Alert, delay = 2000): void {
    if (!this.alreadyExists(alert)) {
      setTimeout(() => {
        this.alerts = this.alerts.filter((a) => !(a.text === alert.text && a.type === alert.type)).concat([alert]);
        if (alert.dismissOnTimeout) {
          setTimeout(() => {
            this.alerts = this.alerts.filter((a) => !(a.text === alert.text && a.type === alert.type));
          }, alert.dismissOnTimeout);
        }
      }, delay);
    }
  }
}
