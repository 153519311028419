<nav aria-label="Page navigation" class="page-navigation">
  <p>Page {{ pageNumber + 1 }}/{{ getLastPage() }}</p>
  <p
    tooltip="Click to fetch next batch of rows"
    class="clickable"
    (click)="handleBtnClick(nPages - 1)"
    *ngIf="exactRows && exactRows !== tableRows?.length"
    style="font-size: 0.8rem"
  >
    Loaded {{ tableRows?.length ?? 0 }}/{{ Math.max(tableRows?.length ?? 0, exactRows) }}
  </p>
  <ul class="pagination justify-content-center">
    <li class="page-item m-0 pointer" [class.invisible]="pageNumber === 0" tooltip="First page" (click)="firstPage()">
      <a class="page-link border-0">
        <app-vficon iconPack="source-system-icons" iconName="chevron-left" size="16px"></app-vficon>
      </a>
    </li>
    <li
      class="page-item m-0"
      (click)="previousPage()"
      [ngClass]="{ disabled: pageNumber === 0, pointer: pageNumber !== 0 }"
    >
      <a class="page-link border-0">
        <app-vficon iconPack="source-system-icons" iconName="arrow-left" size="16px"></app-vficon>
      </a>
    </li>
    <li *ngIf="nPages > 5 && pageNumber >= 3" class="page-item m-0 disabled ml-3 mr-1">
      <a class="page-link border-0 px-0">
        <app-vficon iconPack="source-system-icons" iconName="more" size="16px"></app-vficon>
      </a>
    </li>

    <li class="page-item" [class.active]="pageNavigation(1).active">
      <a class="page-link" (click)="handleBtnClick($event)">{{ pageNavigation(1).pageNo }}</a>
    </li>
    <li *ngIf="nPages > 1" class="page-item" [class.active]="pageNavigation(2).active">
      <a class="page-link" (click)="handleBtnClick($event)">{{ pageNavigation(2).pageNo }}</a>
    </li>
    <li *ngIf="nPages > 2" class="page-item" [class.active]="pageNavigation(3).active">
      <a class="page-link" (click)="handleBtnClick($event)">{{ pageNavigation(3).pageNo }}</a>
    </li>
    <li *ngIf="nPages > 3" class="page-item" [class.active]="pageNavigation(4).active">
      <a class="page-link" (click)="handleBtnClick($event)">{{ pageNavigation(4).pageNo }}</a>
    </li>
    <li *ngIf="nPages > 4" class="page-item" [class.active]="pageNavigation(5).active">
      <a class="page-link" (click)="handleBtnClick($event)">{{ pageNavigation(5).pageNo }}</a>
    </li>

    <li *ngIf="nPages > 5 && nPages - pageNumber > 3" class="page-item m-0 disabled ml-1 mr-3">
      <a class="page-link border-0 px-0">
        <app-vficon iconPack="source-system-icons" iconName="more" size="16px"></app-vficon>
      </a>
    </li>
    <li
      class="page-item m-0"
      (click)="nextPage()"
      [ngClass]="{
        disabled: pageNumber === nPages - 1,
        pointer: pageNumber !== nPages - 1
      }"
    >
      <a class="page-link border-0">
        <app-vficon iconPack="source-system-icons" iconName="arrow-right" size="16px"></app-vficon>
      </a>
    </li>
    <li
      class="page-item m-0 pointer"
      tooltip="Last page"
      [class.invisible]="pageNumber === nPages - 1"
      (click)="lastPage()"
    >
      <a class="page-link border-0">
        <app-vficon iconPack="source-system-icons" iconName="chevron-right" size="16px"></app-vficon>
      </a>
    </li>
  </ul>
</nav>

<select class="d-block mx-auto" [(ngModel)]="pageSize" (change)="handleChangePageSize($event.target.value)">
  <option *ngFor="let v of validPageSizes" [value]="v">{{ v }}</option>
</select>
